<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row row-cols-sm-2 mb-4" :class="rowColsMd">
      <div class="col" v-for="item in dataCategory" :key="item.id">
        <router-link to="#" @click="selected(item)">
          <span v-if="item === active">
            <a-button type="primary" style="text-align:middle;" block>{{ item.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()) }}</a-button>
          </span>
          <span v-else>
            <a-button type="link" style="text-align:middle;" block>{{ item.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()) }}</a-button>
          </span>
        </router-link>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-12">
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="ml-3" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <a-spin
      :spinning="spinning"
      class="mx-auto d-block">
      <a-empty v-if="dataActive.length <= 0" :image="simpleImage" />
      <span v-else>
        <a-collapse 
          class="mt-2" 
          :data-source="dataActive"
          v-for="(item, index) in dataActive"
          :key="item.id"
          ghost
          :bordered="false">
          <a-collapse-panel
            class="h5 font-weight-bold panel-hover"
            style="background-color: #ffffff;" 
            :key="++index" 
            :header="item.name">
            <span 
            style="font-weight: normal;"
            v-html="item.content"></span>
          </a-collapse-panel>
        </a-collapse>
      </span>
    </a-spin>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import apiClient from '@/services/axios'
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: 'VbFAQ',
  components: {
  },
  setup() {
    const searchText = ref('')

    const data = ref([])
    const dataActive = ref([])
    const dataCategory = ref([])
    const q = ref('')
    const category = ref('')
    const errorMessage = ref(null)
    const rowColsMd = ref()
    const active = ref()
    const arrCategory = []
    const spinning = ref(true)

    const params = ref({})
    const fetchDataKategori = () => {
      const _params = {
        q: q.value,
        category: category.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/faqs', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          data.value.forEach((value, index) => {
            arrCategory.push(value.category.toLowerCase().trim())
          });
          dataCategory.value = [...new Set(arrCategory)]
          rowColsMd.value = 'row-cols-md-' + dataCategory.value.length
          if (active.value === '' && category.value === '') {
            active.value = arrCategory[0]
            category.value = arrCategory[0]
            fetchData()
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchData = () => {
      const _params = {
        q: q.value,
        category: category.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/faqs', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          dataActive.value = items
        })
        .finally(() => {
          spinning.value = false
          state.isFetching = false
        })
    }

    const selected = (item) => {
      active.value = item
      category.value = item
      fetchData()
    }

    const state = reactive({
      isFetching: false,
    })

    const search = () => {
      arrCategory.push()
      fetchData() 
    }

    onMounted(() => {
      active.value = ''
      category.value = ''
      fetchDataKategori()
    })

    return {
      data,
      dataActive,
      dataCategory,
      searchText,
      q,
      category,
      search,
      state,
      ...toRefs(state),
      fetchData,
      fetchDataKategori,
      errorMessage,
      rowColsMd,
      selected,
      active,
      spinning,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
.panel-hover:hover {
  opacity: 0.7;
}
</style>

